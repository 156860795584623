import React from "react";
import CameraButton from "./CameraButton";
import LeaveRoomButton from "./LeaveRoomButton";
import MicButton from "./MicButton";
// import SwitchToScreenSharingButton from "./SwitchToScreenSharingButton";
import { connect } from "react-redux";
import SwitchToScreenSharingButton from "./SwitchToScreenSharingButton";
import EndCallPopup from "../../components/popups/EndCallPopup/EndCallPopup";
import { useState } from "react";
// import ParticipantsBtn from "./ParticipantsBtn";
// import ChatBtn from "./ChatBtn";
// import Morebtn from "./Morebtn";
// import Recording from "./Recording";
import Overlay from "../../RoomPage/Overlay"
import LeaveLoader from "../../components/LeaveLoader/LeaveLoader";

const VideoButtons = ({ connectOnlyWithAudio, roomId, userId, hostLeave, isRoomHost, meetEnd, frontCam, stable, frontMic, isSas, hostLink, guestLink, apiEndPoint, setPopupActive, popupActive}) => {
  // const { connectOnlyWithAudio } = props;

  // const [popupActive, setPopupActive] = useState(false)
  const [callEnd, setCallEnd] = useState(false);
  const [showLoder, setShowLoder] = useState(false);

  return (
    // <div className="video_buttons_container">
    //   <ParticipantsBtn/>
    //   <MicButton />
    //   {!connectOnlyWithAudio && <CameraButton />}
    //   <LeaveRoomButton />
    //   {!connectOnlyWithAudio && <SwitchToScreenSharingButton />}
    //   <ChatBtn/>
    //   {/* <Recording/> */}
    //   <Morebtn  roomId={roomId}/>
    //   {/* <button>chat</button> */}
    // </div>
    <>
      <LeaveLoader
        isActive={showLoder}
      />
      <div></div>
      <div className="meetingButtons">
        <div className="Mbtnfooter hidemob">
          <button> {!connectOnlyWithAudio && <SwitchToScreenSharingButton />}</button>
          <p className="text13">Present</p>
        </div>

        <div className="Mbtnfooter">
          <button>  <MicButton stable={stable} frontMic={frontMic} /></button>
          <p className="text13">Microphone</p>
        </div>

        <div className="Mbtnfooter">
          <button>  {!connectOnlyWithAudio && <CameraButton frontCam={frontCam} stable={stable} />}</button>
          <p className="text13">Camera</p>
        </div>

        <div className="MleaveMeeting">
          <LeaveRoomButton hostLeave={hostLeave} isRoomHost={isRoomHost} hostId={roomId} meetEnd={meetEnd} isSas={isSas} hostLink={hostLink} guestLink={guestLink} userId={userId} apiEndPoint={apiEndPoint} setPopupActive={setPopupActive} callEnd={callEnd} />
        </div>
      </div>


      {/* <buttpn className="dangerBtn">Leave Meeting</buttpn> */}
      <EndCallPopup isActive={popupActive} setPopupActive={setPopupActive} setCallEnd={setCallEnd} hostLeave={hostLeave} isRoomHost={isRoomHost} hostId={roomId} meetEnd={meetEnd} isSas={isSas} hostLink={hostLink} guestLink={guestLink} userId={userId} apiEndPoint={apiEndPoint} setShowLoder={setShowLoder} />
    </>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(VideoButtons);
