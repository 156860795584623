import React from 'react'
import './LeaveLoader.css'
const LeaveLoader = ({isActive}) => {
  return (
    <div className={`popupWrapper endCallPopup ${isActive && 'active'}`}>
    <div className="popupContainer">
        <div className="popupBody">
          <div className="FormBtnAddon FormBtnAddon2">
            <div class="loader--default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            
          </div>
          <h3 className='endCallText'>Redirecting to Feedback Page</h3>
          </div>
        </div>
    </div>
</div>
  )
}

export default LeaveLoader