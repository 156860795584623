import React from "react";

// const Button = ({ buttonText, cancelButton = false, onClickHandler }) => {
//   const buttonClass = cancelButton
//     ? "join_room_cancel_button"
//     : "join_room_success_button";

//   return (
//     <button onClick={onClickHandler} className={buttonClass}>
//       {buttonText}
//     </button>
//   );
// };

const JoinRoomButtons = ({ handleJoinRoom, isRoomHost,name, svg }) => {
  const successButtonText = isRoomHost ? "Join" : "Join";

  // const pushToIntroductionPage = () => {
  //   history.push("/");
  // };

  return (
    // <div className="primaryBtn">
    //  {name!=="" && <Button buttonText={successButtonText} onClickHandler={handleJoinRoom} />}  
    //   <Button
    //     buttonText="Cancel"
    //     cancelButton
    //     onClickHandler={pushToIntroductionPage}
    //   />
    // </div>
    <>
    {name!=="" && <div className="primaryBtn flexbtn" onClick={handleJoinRoom}>{successButtonText} Meeting 
    {svg}
    </div>
    }  
    </>
  );
};

export default JoinRoomButtons;
