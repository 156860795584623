import React from "react";
import {completeInterview, completeInterview2} from "../../utils/api";

const LeaveRoomButton = ({hostLeave, isRoomHost, hostId, meetEnd, isSas, guestLink, hostLink,userId, apiEndPoint, setPopupActive, callEnd}) => {
  // const handleRoomDisconnection = async() => {
  //   // const siteUrl = window.location.origin;
  //   // window.location.href = siteUrl;
   
  // if(isRoomHost && isSas){
  //   // completeInterview(hostId);
  //   const obj = {
  //     roomId: hostId,
  //     userId: userId,
  //     apiEndPoint
  //   }
  //   try{
      
  //     const response = await completeInterview2(obj)
  //     console.log("don",response);
  //    if(response.status === 200){
  //      const siteUrl = `${hostLink}`
  //     //  window.location.href = siteUrl;
  //      console.log("tryyyy");
  //    }
  //   }catch (error) {
  //     // Handle API call error
  //     console.log('Error:', error);
  //   }
  //     // console.log(hostLink);
  //   } else if(!isRoomHost && isSas){
  //     const obj = {
  //       roomId: hostId,
  //       userId: userId,
  //       apiEndPoint
  //     }
  //     try{
  //       const response = await completeInterview2(obj)
  //       if(response.status === 200){
  //        const siteUrl = `${guestLink}`
  //        window.location.href = siteUrl;
  //       // console.log(guestLink);
  //         }
  //      }catch (error) {
  //     // Handle API call error
  //        console.log('Error:', error);
  //    }
  //   }else{
  //     const siteUrl = `https://app.getprepped.in/login`
  //     window.location.href = siteUrl;
  //   }
    
  // };
  
  // if(hostLeave || meetEnd || callEnd){
  //   console.log(hostLeave, meetEnd, callEnd, "don");
  //   handleRoomDisconnection();
  // }

  // if(callEnd){
  //   console.log("call is end ");
  // }
  
 const  onclickBtn=()=>{
    setPopupActive(true)
    // handleRoomDisconnection();
  } 
  
  return (
  // <button className="Mbtnfooter" onClick={onclickBtn}><p className="text13">Leave Meeting</p></button>
  <div className="Mbtnfooter MBtnLeave">
          <button onClick={onclickBtn}><svg className="leave" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" /></svg>
</button>
          <p className="text13">Leave</p>.
        </div>
  );
};

export default LeaveRoomButton;


// if(isRoomHost){
//   completeInterview(hostId);
//   const siteUrl = `https://mentor.getprepped.in/createFeedback?id=${hostId}`
//   window.location.href = siteUrl;
// }else{
  //   // const siteUrl = `http://localhost:3000/join-room?`
  //   const siteUrl = `https://mentee.getprepped.in/createFeedback?id=${hostId}`
  
  //   window.location.href = siteUrl;
  // }
  
  
// } else if(isRoomHost && !isSas){
//   completeInterview(hostId);
//     const siteUrl = `https://mentor.getprepped.in/createFeedback?id=${hostId}`
//     window.location.href = siteUrl;