import React, { useEffect, useState } from 'react'
import './EndCallPopup.css'
import Button from '../../Button/Button'
import { completeInterview2 } from '../../../utils/api';
import LeaveLoader from '../../LeaveLoader/LeaveLoader';

const EndCallPopup = ({isActive, setPopupActive, setCallEnd, hostLeave, isRoomHost, hostId, meetEnd, isSas, guestLink, hostLink,userId, apiEndPoint, setShowLoder }) => {
 
  const [leaveLoader,setLeaveLoader] = useState(false);

  /////////< Complete Interview_API-Hitting>///////////
  const handleRoomDisconnection = async() => {
   
  if(isRoomHost && isSas){
    // completeInterview(hostId);
    const obj = {
      roomId: hostId,
      userId: userId,
      apiEndPoint
    }
    try{
      const response = await completeInterview2(obj)
      // console.log("don",response
     if(response.status === 200){
       const siteUrl = `${hostLink}`
       window.location.href = siteUrl;
      //  console.log("tryyyy");
     }
    }catch (error) {
      // Handle API call error
      console.log('Error:', error);
    }
      // console.log(hostLink);
    } else if(!isRoomHost && isSas){
      const obj = {
        roomId: hostId,
        userId: userId,
        apiEndPoint
      }
      try{

        const response = await completeInterview2(obj)
        if(response.status === 200){
         const siteUrl = `${guestLink}`
         window.location.href = siteUrl;
        // console.log(guestLink);
          }
       }catch (error) {
      // Handle API call error
         console.log('Error:', error);
     }
    }else{
      const siteUrl = `https://app.getprepped.in/login`
      window.location.href = siteUrl;
    }
    
  };

//////////////////////Automatic meeting End //////////////////////
const handleRoomDisconnection2 = async() => {
  if(isRoomHost && isSas){
    // completeInterview(hostId);
    const obj = {
      roomId: hostId,
      userId: userId,
      apiEndPoint
    }
    try{
      const response = await completeInterview2(obj)
      // console.log("don",response
     if(response.status === 200){
       const siteUrl = `${hostLink}`
       window.location.href = siteUrl;
      //  console.log("tryyyy");
     }
    }catch (error) {
      // Handle API call error
      console.log('Error:', error);
    }
      // console.log(hostLink);
    } else if(!isRoomHost && isSas){
      const siteUrl = `${guestLink}`
      window.location.href = siteUrl;
    }else{
      const siteUrl = `https://app.getprepped.in/login`
      window.location.href = siteUrl;
    }
    
  };

  useEffect(()=>{

    if(hostLeave || meetEnd){
      console.log(hostLeave, meetEnd, "don");
      setShowLoder(true)
      handleRoomDisconnection2();
    }
  },[meetEnd])
    
  const onClickEndCall =()=>{
    handleRoomDisconnection();
    setPopupActive(false)
    setLeaveLoader(true)
  }

  


  return (
    <>
    {
      leaveLoader ?
    <LeaveLoader
    isActive={leaveLoader}
    />
    :
    <div className={`popupWrapper endCallPopup ${isActive && 'active'}`}> 
        <div className="popupContainer">
            <div className="popupHeader">
                <span className="close"><img src="/assets/icons/close.svg" alt=""  onClick={()=>setPopupActive(false)}/></span>
            </div>
                <div className="popupBody">
              <div className="FormBtnAddon">
                <h3 className='endCallText'>Are you sure you want to end the call?</h3>
                <h3 className='short'>You won't be able to rejoin. </h3>
              </div>
            </div>    
            <div className="popupFooter">
              <div className="buttonWrapper">
                <Button className={'BlueFillButton'} name={'Rejoin'} onClick={()=>window.location.reload()}/>
                <Button className={'RedFillButton'} name={'End Call'}  onClick={onClickEndCall}/>
              </div>
            </div>
        </div>
    </div>
}
    </>
  )
}

export default EndCallPopup