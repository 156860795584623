import React from "react";
import Button from "../components/Button/Button";

const JoinRoomTitle = ({ isRoomHost, id, infoData }) => {
  // const titleText = isRoomHost ? "Host Meeting" : "Join Meeting";
  const titleText = isRoomHost ? "Ready to Join?" : "Ready to Join?";

  return (
    <>
      <p className="Mtitletext mb40">{titleText}</p>

      <div className="MmeetingDetails">
        <div className="MmeetingId">
          <span className="textId">Meeting ID: </span>
          <p className="textidds mt6">{id?id:"NH78LJU7575DDD"}</p>
        </div>
        {/* <div className="MmeetingId">
    <span className="textId">Name: </span>
    <p className="textidds mt6">Yashwant Rao</p>
  </div> */}
        <div className="MmeetingId">
          <span className="textId">Meeting With: </span>
          <p className="textidds mt6">{infoData?.Data?.Users[0]?.meetingWith ? infoData?.Data?.Users[0]?.meetingWith :"N/A" }</p>
        </div>
        <div className="MmeetingId">
          <span className="textId">Agenda: </span>
          <div className='flex'>
                  <div class="tooltipnew tooltip-scroll">
                  <p className="textidds mt6 lineclamp">{infoData?.Data?.Users[0]?.agenda ? infoData?.Data?.Users[0]?.agenda :"N/A" }</p>
                    <div class="toolwrappertext">
                      <span class="tooltip-text">{infoData?.Data?.Users[0]?.agenda ? infoData?.Data?.Users[0]?.agenda :"N/A" }</span>
                    </div>
                  </div>
                </div>
        </div>

      </div>


    </>
  );
};

export default JoinRoomTitle;
