const Actions = {
  SET_IS_ROOM_HOST: "SET_IS_ROOM_HOST",
  SET_CONNECT_ONLY_WITH_AUDIO: "SET_CONNECT_ONLY_WITH_AUDIO",
  SET_IDENTITY: "SET_IDENTITY",
  SET_ROOM_ID: "SET_ROOM_ID",
  SET_SHOW_OVERLAY: "SET_SHOW_OVERLAY",
  SET_SHOW_OVERLAY2: "SET_SHOW_OVERLAY2",
  SET_PARTICIPANTS: "SET_PARTICIPANTS",
  SET_MESSAGES: "SET_MESSAGES",
  SET_ACTIVE_CONVERSATION: 'SET_ACTIVE_CONVERSATION',
  SET_DIRECT_CHAT_HISTORY: 'SET_DIRECT_CHAT_HISTORY',
  SET_SOCKET_ID: 'SET_SOCKET_ID',
  SET_VALID:'SET_VALID',
  SET_SHOWCHAT:"SET_SHOWCHAT",
  SET_SHOWPARTICIPANTS:"SET_SHOWPARTICIPANTS",
  SET_HOST_ID:'SET_HOST_ID',
  SET_SHAREBTN:'SET_SHAREBTN',
  SET_MIKEINFO:'SET_MIKEINFO',
  SET_HOSTLEAVE:'SET_HOSTLEAVE',
  SET_MEETEND:'SET_MEETEND',
  SET_FRONTMIC:'SET_FRONTMIC',
  SET_FRONTCAM:'SET_FRONTCAM',
  SET_STABLE:'SET_STABLE',
  SET_HOSTLINK:'SET_HOSTLINK',
  SET_GUESTLINK:'SET_GUESTLINK',
  SET_ISSAS:'SET_ISSAS',
  SET_USERID:'SET_USERID',
  SET_LOGO:'SET_LOGO',
  SET_APIENDPOINT:'SET_APIENDPOINT',
  SET_STARTTIME:'SET_STARTTIME',
  SET_MEETINFO:'SET_MEETINFO',
  SET_ISMENTOR:'SET_ISMENTOR'
};

export const setIsRoomHost = (isRoomHost) => {
  return {
    type: Actions.SET_IS_ROOM_HOST,
    isRoomHost,
  };
};

export const setConnectOnlyWithAudio = (onlyWithAudio) => {
  return {
    type: Actions.SET_CONNECT_ONLY_WITH_AUDIO,
    onlyWithAudio,
  };
};

export const setIdentity = (identity) => {
  return {
    type: Actions.SET_IDENTITY,
    identity,
  };
};

export const setUserId = (userId) => {
  return {
    type: Actions.SET_USERID,
    userId,
  };
};

export const setRoomId = (roomId) => {
  return {
    type: Actions.SET_ROOM_ID,
    roomId,
  };
};

export const setShowOverlay = (showOverlay) => {
  return {
    type: Actions.SET_SHOW_OVERLAY,
    showOverlay,
  };
};

export const setShowOverlay2 = (showOverlay2) => {
  return {
    type: Actions.SET_SHOW_OVERLAY2,
    showOverlay2,
  };
};
export const setValid = (text) => {
  return {
    type: 'SET_VALID',
    text,
  };
};


export const setParticipants = (participants) => {
  return {
    type: Actions.SET_PARTICIPANTS,
    participants,
  };
};

export const setMessages = (messages) => {
  return {
    type: Actions.SET_MESSAGES,
    messages,
  };
};

export const setActiveConversation = (activeConversation) => {
  return {
    type: Actions.SET_ACTIVE_CONVERSATION,
    activeConversation
  }
}

export const setDirectChatHistory = (directChatHistory) => {
  return {
    type: Actions.SET_DIRECT_CHAT_HISTORY,
    directChatHistory
  }
}

export const setSocketId = (socketId) => {
  return {
    type: Actions.SET_SOCKET_ID,
    socketId
  }
}
export const setShowChat =(showChat)=>{
  return{
    type:Actions.SET_SHOWCHAT,
    showChat
  }
}

export const setShowParticipants =(showParticipants)=>{
  return{
    type:Actions.SET_SHOWPARTICIPANTS,
    showParticipants
  }
}

export const setHostId =(hostId)=>{
  return {
    type: Actions.SET_HOST_ID,
    hostId
  }
}

export const setShareBtn =(shareBtn)=>{
  return {
    type: Actions.SET_SHAREBTN,
    shareBtn
  }
}

export const setMikeInfo =(mikeInfo)=>{
  return{
    type:Actions.SET_MIKEINFO,
    mikeInfo
  }
}

export const setHostLeave=(hostLeave)=>{
  return{
    type:Actions.SET_HOSTLEAVE,
    hostLeave
  }
}

export const setMeetEnd =(meetEnd)=>{
  return{
    type:Actions.SET_MEETEND,
    meetEnd
  }
}

export const setFrontCam =(frontCam)=>{
  return{
    type:Actions.SET_FRONTCAM,
    frontCam
  }
}

export const setFrontMic =(frontMic)=>{
  return{
    type:Actions.SET_FRONTMIC,
    frontMic
  }
}

export const setStable =(stable)=>{
  return{
    type:Actions.SET_STABLE,
    stable
  }
}

export const setHostLink =(hostLink)=>{
  return{
    type:Actions.SET_HOSTLINK,
    hostLink
  }
}

export const setGuestLink =(guestLink)=>{
  return{
    type:Actions.SET_GUESTLINK,
    guestLink
  }
}

export const setIsSas =(isSas)=>{
  return{
    type:Actions.SET_ISSAS,
    isSas
  }
}

export const setLogo =(logo)=>{
  return{
    type:Actions.SET_LOGO,
    logo
  }
}

export const setApiEndPoint =(apiEndPoint)=>{
  return{
    type:Actions.SET_APIENDPOINT,
    apiEndPoint
  }
}

export const setStartTime =(startTime)=>{
  return{
    type:Actions.SET_STARTTIME,
    startTime
  }
}

export const setMeetInfo =(meetInfo)=>{
  return{
    type:Actions.SET_MEETINFO,
    meetInfo
  }
}

export const setIsMentor=(isMentor)=>{
  return{
    type:Actions.SET_ISMENTOR,
    isMentor
  }
}


export default Actions;
