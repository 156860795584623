import React, { useState } from "react";
import { useEffect } from "react";

// import CameraButtonImg from "../../resources/images/camera.svg";
// import CameraButtonImgOff from "../../resources/images/cameraOff.svg";
import * as webRTCHandler from "../../utils/webRTCHandler";

const CameraButton = ({frontCam, stable}) => {
  const [isLocalVideoDisabled, setIsLocalVideoDisabled] = useState(false);

  const handleCameraButtonPressed = () => {
    webRTCHandler.toggleCamera(isLocalVideoDisabled);
    setIsLocalVideoDisabled(!isLocalVideoDisabled);
  };

 useEffect(()=>{
  if(frontCam && stable){
    webRTCHandler.toggleCamera(frontCam?false:true);
    setIsLocalVideoDisabled(!isLocalVideoDisabled);
  }
 },[stable, frontCam]);

  return (
    // <div className="video_button_container">
    //   <img
    //     src={isLocalVideoDisabled ? CameraButtonImgOff : CameraButtonImg}
    //     className={`video_button_image${isLocalVideoDisabled?"-active":""}`}
    //     onClick={handleCameraButtonPressed}
    //     alt="cam"
    //   />
    // </div>
    <img
        src={isLocalVideoDisabled ? "../assets/icons/camNot.svg" : "../assets/icons/video-small.svg"}
       
        onClick={handleCameraButtonPressed}
        alt="cam"
      />

  );
};

export default CameraButton;
