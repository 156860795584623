import React from 'react'
import './DissconnectPopup.css'
import Button from "../../Button/Button"
const DissconnectPopup = () => {

    const handleRejoin =()=>{
        window.location.reload();
    }

  return (
    <div className= "popupWrapper endCallPopup active">
    <div className="popupContainer">
        {/* <div className="popupHeader">
            <span className="close"><img src="/assets/icons/close.svg" alt="" /></span>
        </div> */}
        <div className="popupBody">
          <div className="FormBtnAddon">
            <h3 className='endCallText'>You lost your network connection... Trying to reconnect the call </h3>
          </div>
        </div>
        <div className="popupFooter">
          <div className="buttonWrapper">
            {/* <Button className={'GreyFillButton'} name={'Cancel'} onClick={()=>setPopupActive(false)}/> */}
            <Button className={'RedFillButton'} name={'Rejoin Call'} onClick={()=>handleRejoin()} />
          </div>
        </div>
    </div>
</div>
  )
}

export default DissconnectPopup