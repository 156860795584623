import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import IntroductionPage from "./IntroductionPage/IntroductionPage";
import JoinRoomPage from "./JoinRoomPage/JoinRoomPage";
import RoomPage from "./RoomPage/RoomPage";
import { connectWithSocketIOServer } from "./utils/wss";

import "./App.css";
import MeetingPage from "./meetingPage/MeetingPage";

function App() {
  useEffect(() => {
    connectWithSocketIOServer();
  }, []);

  return (
    <>
      {/* <Header /> */}
      <div className="appContainer">
        <div className="routesContainer">
          <Router>
            <Switch>
              <Route path='/meeting'>
                <MeetingPage/>
              </Route>
              <Route path="/join-room">
                <JoinRoomPage />
              </Route>
              <Route path="/room">
                <RoomPage />
              </Route>
              <Route path="/">
                <IntroductionPage />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    </>
  );
}

export default App;
