import React, { useEffect, useId, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setIsRoomHost, setHostId, setHostLink, setGuestLink, setIsSas, setUserId, setLogo,setApiEndPoint, setMeetInfo, setIsMentor } from "../store/actions";
import JoinRoomTitle from "./JoinRoomTitle";
import JoinRoomContent from "./JoinRoomContent";
// import Checkbox from "../components/globalComponents/checkbox/Checkbox";
import * as webRTCHandler from "../utils/webRTCHandler";
import { getMeetingInfo } from "../utils/api";

import "./JoinRoomPage.css";
import Overlay from "../RoomPage/Overlay";
import DissconnectPopup from "../components/popups/DissconnectPopup/DissconnectPopup";

const JoinRoomPage = (props) => {
  const {
    setIsRoomHostAction,
    isRoomHost,
    setHostIdAction,
    connectOnlyWithAudio,
    showOverlay,
    setIsSasAction,
    setHostLinkAction,
    setGuestLinkAction,
    setUserIdAction,
    hostLink,
    guestLink,
    setLogoAction,
    setApiEndPointAction,
    setMeetInfoAction, 
    setIsMentorAction
    // frontCam
  } = props;
 
//  useEffect(()=>{
//   console.log(frontCam,"Effect6545465");
//  },[frontCam])
  const search = useLocation().search;
  // const id = search.slice(14);
  const query = new URLSearchParams((search), [search]);
  const id = query.get("id")
  const userId = query.get("userId");
 
// api call for the dataBase 
 const [infoData, setInfoData]=  useState("");
 const [loader,setLoader]= useState(true)
 const getData = async()=>{
  const obj = {
    id:id,
    userId:userId
  }
  
  const data= await getMeetingInfo(obj)
  setInfoData(data)
  const url =  window.location.href
  // console.log("xxyyyzz",url);
  localStorage.setItem("link",url)
}

  useEffect(() => {
    // const isRoomHost = new URLSearchParams(search).get("host");
    getData();
    // const isRoomHost = 
    // if (infoData?.Data?.Users[0]?.isHost) {
    //   setIsRoomHostAction(true);
    //   setHostIdAction(id);
    // }
 
    if (!connectOnlyWithAudio) {
      webRTCHandler.GetFrontPageStreamInitFrontPage();
    }
      // eslint-disable-next-line
  }, []);

  // console.log("infoData", infoData?.Data?.Users[0]?.apiEndPoint );

  useEffect(() => {
    if(infoData) {
      // console.log("logo from the back end", infoData?.Data?.Users[0]?.Organization?.logoUrl );
      setLogoAction(infoData?.Data?.Users[0]?.Organization?.logoUrl)
      setApiEndPointAction(infoData?.Data?.Users[0]?.apiEndPoint)
      setMeetInfoAction(infoData)
      setLoader(false)
    }

    if (infoData?.Data?.Users[0]?.isSas && infoData?.IsSuccess && id && userId && infoData?.Data?.Users[0]?.isHost ) {
      console.log("Run the loop of host", );
      setIsRoomHostAction(true);
      setIsMentorAction(true);
      setHostIdAction(id);
      setUserIdAction(userId);
      setIsSasAction(true);
      setHostLinkAction(infoData?.Data?.Users[0]?.redirectLink);
    }

    // if(infoData?.Data?.Users[0]?.isSas && infoData?.Data?.Users[0]?.isHost ){
    //   setIsSasAction(true)
    //   setUserIdAction(userId);
    //   setGuestLinkAction(infoData?.Data?.Users[0]?.redirectLink)
    //   console.log("hello");
    // }

    if(infoData?.Data?.Users[0]?.isSas && !infoData?.Data?.Users[0]?.isHost){
      setHostIdAction(id);
      setIsSasAction(true);
      setUserIdAction(userId);
      setGuestLinkAction(infoData?.Data?.Users[0]?.redirectLink)
      console.log("not host----");
    }

    // if(infoData?.Data?.Users[0]?.isSas && infoData?.Data?.Users[0]?.isHost){
    //   setIsSasAction(true)
    //   setHostLinkAction(infoData?.Data?.Users[0]?.redirectLink)
    //   setHostIdAction(id);
    //   setUserIdAction(userId)
    //   setIsMentorAction(true)
    // }

    
    // if(infoData?.Data?.Users[0]?.isSas && !infoData?.Data?.Users[0]?.isHost){
    //   console.log("sas & guest");
    //   setIsSasAction(true)
    //   setGuestLinkAction(infoData?.Data?.Users[0]?.redirectLink)
    //   setUserIdAction(userId)
      
    // }
    
  },[infoData])
  
  // if(!infoData?.Data?.Users[0]?.isSas && !infoData?.Data?.Users[0]?.isHost){
  //   setGuestLinkAction(infoData?.Data?.Users[0]?.redirectLink)
  //   setUserIdAction(userId)
    
  // }
  // console.log("data of the api call", infoData);  
  // console.log("data of the api call issuccess", infoData?.IsSuccess);
  // console.log("room from SAS", infoData?.Data?.Users[0]?.isSas)
  // console.log("hostLink",hostLink);
  // console.log("guestLink",guestLink);
     

  
////////////////////////////////ON-LINE/OF-LINE LOGIC////////////////////////////////////

const [isOnline, setIsOnline] = useState(navigator.onLine);

useEffect(() => {
  // Add event listener for the 'online' event
  // window.addEventListener("online", handleOnline);

  // Add event listener for the 'offline' event
  window.addEventListener("offline", handleOffline);

  return () => {
    // Remove event listeners when the component is unmounted
    // window.removeEventListener("online", handleOnline);
    window.removeEventListener("offline", handleOffline);
  };
}, []);


const handleOffline = () => {
  setIsOnline(false);

  console.log("offline");
};

// const handleOnline = () => {
//   setIsOnline(true);

//   console.log("online");
// };

  return (
    <>
    {showOverlay && <Overlay/>}
    {loader && <Overlay/>}
    {!isOnline && <DissconnectPopup/>}
      {/* <div style={connectOnlyWithAudio ? {display:"none"} : null} className="introductionPageContainer" >
      <div className="introductionPageContentBox">
        <JoinRoomTitle isRoomHost={isRoomHost} />
        <JoinRoomContent />
      </div>
    </div>
    <div style={connectOnlyWithAudio ? null : {display:"none"}} className="introductionPageContainer" >
      <div className="introductionPageContentBox">
      <JoinRoomTitle isRoomHost={isRoomHost} />
        <JoinRoomContent />
      </div>
    </div> */}

      <div className="MfrontpageView introductionPageContainer">
        <div className="introductionPageContentBox">
          <JoinRoomTitle isRoomHost={isRoomHost} id={id} infoData={infoData}/>
          <JoinRoomContent />
        </div>
      </div>
    </>
    // <div className="introductionPageContainer">
    //   <div className="userVideo">
    //     <div className="userVideoHeader">
    //       <img src="../assets/icons/micIcon.svg" alt="" className="videoUsername" />
    //     </div>
    //     <img
    //       className="userVideoBox"
    //       src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
    //     <div className="userVideoFooter">
    //       <p className="videoUsername">Diana</p>
    //       <img className="videoBtn" src="../assets/icons/mic-small.svg" alt="" />
    //       <img className="videoBtn" src="../assets/icons/video-small.svg" alt="" />
    //     </div>
    //   </div>
    //   <div className="introductionPageContentBox">
    //     <h2 className="heading">Host Meeting</h2>
    //     <div className="formGroup">
    //       <label htmlFor="name" className="formLabel">Name</label>
    //       <input type="text" className="formInput" placeholder="Enter your Name" />
    //     </div>
    //     <Checkbox label={'Only audio'}/>
    //     <div className="primaryBtn">Join Meeting</div>
    //   </div>
    // </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
    setHostIdAction: (hostId) => dispatch(setHostId(hostId)),
    setIsSasAction:(isSas)=>dispatch(setIsSas(isSas)),
    setHostLinkAction:(hostLink)=>dispatch(setHostLink(hostLink)),
    setGuestLinkAction:(guestLink)=>dispatch(setGuestLink(guestLink)),
    setUserIdAction:(userId)=>dispatch(setUserId(userId)),
    setLogoAction:(logo)=>dispatch(setLogo(logo)),
    setApiEndPointAction:(apiEndPoint)=>dispatch(setApiEndPoint(apiEndPoint)),
    setMeetInfoAction:(meetInfo)=>dispatch(setMeetInfo(meetInfo)),
    setIsMentorAction:(isMentor)=>dispatch(setIsMentor(isMentor))

  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(JoinRoomPage);
