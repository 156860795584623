import React from 'react'
// import { useEffect } from 'react';
// import { useState } from 'react';
import './MeetingPageStyle.css'
// import Header from '../components/header/Header'

const MeetingPage = () => {

//     const[seconds, setSeconds]=useState(0);
//     const [minutes, setMinutes]=useState(0)
  
//     var timer;
//   useEffect(()=>{
//   timer = setInterval(()=>{
//     setSeconds(seconds+1);
  
//         if(seconds===59){
//           setMinutes(minutes+1)
//           setSeconds(0)
//         }
//         if (minutes===2){
//             // console.log("huurrrrraaaa")
//         }
//   }, 1000)
  
//   return ()=> clearInterval(timer)
  
//   });

    return (
        <div className="meetingPageOuter">
            <div className="meetingPageContainer">
                <div className="meetingPageHeader">
                    <div className="headerContainer">
                        <img src="../assets/icons/logo.svg" alt="" className='logo' />
                        <div className="recording">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.97754 17.0625C4.53754 17.0625 0.915039 13.4475 0.915039 9C0.915039 4.5525 4.53754 0.9375 8.97754 0.9375C13.4175 0.9375 17.04 4.5525 17.04 9C17.04 13.4475 13.425 17.0625 8.97754 17.0625ZM8.97754 2.0625C5.15254 2.0625 2.04004 5.175 2.04004 9C2.04004 12.825 5.15254 15.9375 8.97754 15.9375C12.8025 15.9375 15.915 12.825 15.915 9C15.915 5.175 12.8025 2.0625 8.97754 2.0625Z" fill="#EF5350" />
                                <path d="M9.00014 12.735C6.93764 12.735 5.26514 11.0625 5.26514 9.00002C5.26514 6.93751 6.93764 5.26501 9.00014 5.26501C11.0626 5.26501 12.7351 6.93751 12.7351 9.00002C12.7351 11.0625 11.0626 12.735 9.00014 12.735ZM9.00014 6.39002C7.56014 6.39002 6.39014 7.56001 6.39014 9.00002C6.39014 10.44 7.56014 11.61 9.00014 11.61C10.4401 11.61 11.6101 10.44 11.6101 9.00002C11.6101 7.56001 10.4401 6.39002 9.00014 6.39002Z" fill="#EF5350" />
                            </svg>
                            <p className="time">01:03:34</p>
                            {/* <p className='time'>{minutes<10?"0"+minutes:minutes}:{seconds<10 ? "0"+seconds: seconds}</p> */}
                        </div>
                    </div>
                </div>
                <div className="meetingVideoArea">
                    <div className="userVideo">
                        <div className="userVideoHeader">
                            <img src="../assets/icons/micIcon.svg" alt="" className="videoUsername" />
                        </div>
                        <img
                            className="userVideoBox small"
                            src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
                        <div className="userVideoFooter">
                            <p className="videoUsername">Diana</p>
                        </div>
                    </div>
                    <div className="userVideo">
                        <div className="userVideoHeader">
                            <img src="../assets/icons/micIcon.svg" alt="" className="videoUsername" />
                        </div>
                        <img
                            className="userVideoBox"
                            src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
                        <div className="userVideoFooter">
                            <p className="videoUsername">Diana</p>
                        </div>
                    </div>

                </div>
                <div className="meetingFooter">
                    <div></div>
                    <div className="meetingButtons">
                        <img className="videoBtn" src="../assets/icons/mic-small.svg" alt="" />
                        <img className="videoBtn" src="../assets/icons/video-small.svg" alt="" />
                    </div>
                    <buttpn className="dangerBtn">Leave Meeting</buttpn>
                </div>
            </div>
            <div className="chatBoxOuter">
                <div className="membersList">
                    <div className="member">
                        <div className="memberDetail">
                            <img src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" className='memberImg' />
                            <div className="memberDetailsRight">
                                <p className="memberName">Cecile</p>
                                <p className="memberPosition">Project Manager</p>
                            </div>
                        </div>
                        <div className="memberRight">
                            <div className="primaryTag">Host</div>
                            <img src="../assets/icons/micOff.svg" alt="" />
                            <img src="../assets/icons/VideoOn.svg" alt="" />
                        </div>
                    </div>
                    <div className="member">
                        <div className="memberDetail">
                            <img src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" className='memberImg' />
                            <div className="memberDetailsRight">
                                <p className="memberName">Cecile</p>
                                <p className="memberPosition">Project Manager</p>
                            </div>
                        </div>
                        <div className="memberRight">
                            <div className="primaryTag">Host</div>
                            <img src="../assets/icons/micOn.svg" alt="" />
                            <img src="../assets/icons/VideoOn.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div className="chatSection">
                    <div className="header">
                        <p className="heading">Chats</p>
                        <img src="../assets/icons/option.svg" alt="" />
                    </div>
                    <div className="chats">
                        <div className="chat">
                            <img src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
                            <div className="chatRight">
                                <div className="chatRightHeader">
                                    <p>from <span>Marry</span> to <span>Everyone</span></p>
                                    <p>05:32 PM</p>
                                </div>
                                <div className="msg">
                                    Hi there, how are you?
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sendMessageBox">
                        <input type="text" className="clearInput" placeholder='Send message...' />
                        <img src="../assets/icons/sendImg.svg" alt="" className='pointer'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MeetingPage