import React from "react";
// import ChatLabel from "./ChatLabel";
import Messages from "./Messages";
import NewMessage from "./NewMessage";

const ChatSection = () => {
  return (
    // <div className="chat_section_container">
    // <div className="chat-wrapper">
    // <ChatLabel />
    //   <Messages />
    //   <NewMessage />
    // </div>
    // </div>
    <>
    <div className="chatSection">
      <div className="header">
        <p className="heading">Chats</p>
        {/* <img src="../assets/icons/option.svg" alt="" /> */}
      </div>
      {/* <div className="chats">
        <div className="chat">
            <img src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
            <div className="chatRight">
                <div className="chatRightHeader">
                    <p>from <span>Marry</span> to <span>Everyone</span></p>
                    <p>05:32 PM</p>
                </div>
                <div className="msg">
                    Hi there, how are you?
                </div>
            </div>
        </div>
    </div>
    <div className="sendMessageBox">
        <input type="text" className="clearInput" placeholder='Send message...' />
        <img src="../assets/icons/sendImg.svg" alt="" className='pointer'/>
    </div> */}
      <Messages />
    </div>
      <NewMessage />
    </>
  );
};

export default ChatSection;
