import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';


const Timer = ({ setMeetingEnd, startTime, setWarningPopup }) => {

  useEffect(() => {
    // console.log("startTime",startTime);
    if (startTime > 25) {
      setShowCountDwn(true)
    }
    if (startTime > 0 && startTime < 30) {
      setMinutes(30 - startTime)
    } else if (startTime > 30) {
      setMinutes(0)
      setMeetingEnd(true);
    }
  }, [startTime])

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(30);
  const [show, setShow] = useState(false);
  const [showCountDwn, setShowCountDwn] = useState(false)
  var timer;
  useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds - 1);

      if (seconds === 0) {
        setMinutes(minutes - 1)
        setSeconds(59)
      }
      if (minutes === 5 && seconds === 1) {
        setWarningPopup(true)
        setShowCountDwn(true)
      }
      if (minutes === 0 && seconds === 0) {
        setMeetingEnd(true);
        setShow(true)
      }
    }, 1000)
    return () => clearInterval(timer)

  });
  return (
    <div className={`recording ${showCountDwn ? "counDtwn" : ""}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99967 2.33331C4.59392 2.33331 1.83301 5.09422 1.83301 8.49998C1.83301 11.9057 4.59392 14.6666 7.99967 14.6666C11.4054 14.6666 14.1663 11.9057 14.1663 8.49998C14.1663 5.09422 11.4054 2.33331 7.99967 2.33331ZM0.833008 8.49998C0.833008 4.54194 4.04163 1.33331 7.99967 1.33331C11.9577 1.33331 15.1663 4.54194 15.1663 8.49998C15.1663 12.458 11.9577 15.6666 7.99967 15.6666C4.04163 15.6666 0.833008 12.458 0.833008 8.49998ZM7.99967 3.99998C8.27582 3.99998 8.49967 4.22384 8.49967 4.49998V8.19096L10.8899 9.3861C11.1369 9.50959 11.237 9.80993 11.1136 10.0569C10.9901 10.3039 10.6897 10.404 10.4427 10.2805L7.77607 8.94719C7.60668 8.8625 7.49967 8.68937 7.49967 8.49998V4.49998C7.49967 4.22384 7.72353 3.99998 7.99967 3.99998Z" fill="#404040" />
      </svg>
      {/* <p className="time">01:03:34</p> */}
      {!show && <p className='time'>{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}</p>}
    </div>
  )
}

export default Timer