import React from "react";
import { connect } from "react-redux";
import chatdp from "../../resources/images/Icons/user.svg"

const Message = ({ author, content, sameAuthor, messageCreatedByMe }) => {
  // const alignClass = messageCreatedByMe
  //   ? "message_align_right"
  //   : "message_align_left";

  const authorText = messageCreatedByMe ? "You" : author;
  // const contentAdditionalStyles = messageCreatedByMe
  //   ? "message_right_styles"
  //   : "message_left_styles";

  return (
    // <div className={`message_container ${alignClass}`}>
    //   {!sameAuthor && <p className="message_title">{authorText}</p>}
    //   <p className={`message_content ${contentAdditionalStyles}`}>{content}</p>
    // </div>

    // <div className="chats">
    <div  className={`chat ${!messageCreatedByMe && "user"}`}>
        {/* <img src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" /> */}
         <img src= {chatdp} alt=""></img>
        <div className="chatRight">
            <div className="chatRightHeader">
                <p><span>{authorText}</span> </p>
                <p>{new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</p>
            </div>
            <div className="msg">
            {content}
            </div>
        </div>
    </div>
// </div>
  );
};

const Messages = ({ messages }) => {
  return (
    // <div className="messages_container">
      <div className="chats">
      {messages.map((message, index) => {
        const sameAuthor =
          index > 0 && message.identity === messages[index - 1].identity;

        return (
          <Message
            key={`${message.content}${index}`}
            author={message.identity}
            content={message.content}
            sameAuthor={sameAuthor}
            messageCreatedByMe={message.messageCreatedByMe}
          />
        );
      })}
      </div>
    // </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(Messages);
