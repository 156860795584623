import React, { useEffect, useState } from "react";
import JoinRoomInputs from "./JoinRoomInputs";
import { connect } from "react-redux";
// import OnlyWithAudioCheckbox from "./OnlyWithAudioCheckbox";
import {
  setConnectOnlyWithAudio,
  setGuestLink,
  setHostId,
  setHostLink,
  setIdentity,
  setIsRoomHost,
  setRoomId,
  setShowOverlay,
} from "../store/actions";
import ErrorMessage from "./ErrorMessage";
import JoinRoomButtons from "./JoinRoomButtons";
import { useHistory } from "react-router-dom";
import { getRoomExists } from "../utils/api";
import { useLocation } from "react-router-dom";
import { getMeetingStatus, updateMentorJoiningStatus, onUserJoined, startInterview } from "../utils/api";
import moment from "moment/moment";


const JoinRoomContent = (props) => {
  const svgimg = [
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 20" fill="none">
      <path d="M10.4998 18.3333C15.1022 18.3333 18.8332 14.6024 18.8332 9.99999C18.8332 5.39762 15.1022 1.66666 10.4998 1.66666C5.89746 1.66666 2.1665 5.39762 2.1665 9.99999C2.1665 14.6024 5.89746 18.3333 10.4998 18.3333Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.4502 12.9417L12.3835 9.99999L9.4502 7.05832" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  ]
  const {
    isRoomHost,
    isSas,
    // setConnectOnlyWithAudio,
    // connectOnlyWithAudio,
    setIsRoomHostAction,
    setHostIdAction,
    setIdentityAction,
    setRoomIdAction,
    setGuestLinkAction,
    setHostLinkAction,
    guestLink,
    hostLink,
    setShowOverlayAction,
    showOverlay,
    apiEndPoint,
    meetInfo
  } = props;

  const [roomIdValue, setRoomIdValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [msgColour, setMsgColour] = useState("")
  const [showBt, setshowBt] = useState("")

  const history = useHistory();
  const { search } = useLocation();

  const query = new URLSearchParams((search), [search]);
  const id = query.get("id")
  const name = query.get("name")
  const userId = query.get("userId")

  const handleJoinRoom = async () => {
    setIdentityAction(name ? name : nameValue);
    setShowOverlayAction(true)
    if (isRoomHost) {
      //api hit for join host
      const obj = {
        id: id,
        name: name,
        time: moment().format("h:mma")
      }

      const obj2 = {
        roomId: id,
        userId: userId
      }

      //  await updateMentorJoiningStatus(obj);
      // await onUserJoined(obj)
      // await startInterview(obj2)
      await createRoom();

    } else {
      // Delay execution of joinRoom() by 9 seconds
      setTimeout(async () => {
        await joinRoom();
      }, 9000); // 9000 milliseconds = 9 seconds
    }
  };


  useEffect(() => {
    if (id) {
      setRoomIdValue(id)
    }
  }, [id])

  const joinRoom = async () => {
    const responseMessage = await getRoomExists(roomIdValue);

    const { roomExists, full } = responseMessage;

    if (roomExists) {
      if (full) {
        setErrorMessage("Meeting is full. Please try again later.");
        setShowOverlayAction(false)
      } else {
        // join a room !
        setRoomIdAction(roomIdValue);
        history.push("/room");
      }
    } else {
      // const interval = setInterval(async() => {
      //  const data= await getMeetingStatus(id)
      //  setStatus(data.Data.mentorJoined);
      // }, 30000);
      if (isSas) {
        // window.location.reload()
        setIsRoomHostAction(true);
        setHostIdAction(id)
        setHostLinkAction(guestLink)
        await createRoom()

        // console.log("helex", isSas);
      } else {
        setShowOverlayAction(false)
        setErrorMessage("Meeting is completed or Invalid Link");
        setMsgColour("newErrorMessage")
        console.log("Id wrong", isSas);
      }
    };

  };
  // useEffect(() => {
  //   if(status) {
  //     console.log("host join the room");
  //       setErrorMessage(" Host has joined the meeting, please join now !");
  //       setMsgColour("newErrorMessage textBlue")
  //   } 
  // },[status])


  const createRoom = async () => {
    const responseMessage = await getRoomExists(roomIdValue);

    const { roomExists, full } = responseMessage;

    if (roomExists) {
      // window.location.reload()
      setIsRoomHostAction(false)
      setGuestLinkAction(hostLink)
      joinRoom();

    } else {
      // const interval = setInterval(async() => { 
      //  const data= await getMeetingStatus(id)
      //  setStatus(data.Data.mentorJoined);
      // }, 30000);
      if (isSas) {
        // window.location.reload()
        console.log("abel to join", isSas);

        const obj = {
          id: id,
          name: name,
          time: moment().format("h:mma")
        }

        const obj2 = {
          roomId: id,
          userId: userId,
          apiEndPoint: apiEndPoint
        }

        //  await onUserJoined(obj)
        await startInterview(obj2)
        history.push("/room");
      } else {
        setErrorMessage("Please wait for the Host to start the meeting");
        setMsgColour("newErrorMessage")
        console.log("not able to join not sas", isSas);
      }
    };
    // history.push("/room");
  };

  if(new Date < moment( meetInfo?.Data?.Users[0]?.meetingEndTime).utcOffset("-00:00")){
   
    console.log("The meeting will be close & The Time of the meeting is over ");

  }else{

    console.log("able to join");
  }

// console.log("Projection of Time--> ", meetInfo?.Data?.Users[0]?.meetingEndTime, moment( meetInfo?.Data?.Users[0]?.meetingEndTime).utcOffset("-00:00"));

  return (
    <>
      {/* <JoinRoomInputs
        id={id}
        name={name}
        roomIdValue={roomIdValue}
        setRoomIdValue={setRoomIdValue}
        nameValue={nameValue}
        setNameValue={setNameValue}
        isRoomHost={isRoomHost}
      /> */}
      {/* <OnlyWithAudioCheckbox
        setConnectOnlyWithAudio={setConnectOnlyWithAudio}
        connectOnlyWithAudio={connectOnlyWithAudio}
      /> */}
      {/* { meetInfo?.Data?.Users[0]?.meetingEndTime ? console.log("avalable"): new Date < moment( meetInfo?.Data?.Users[0]?.meetingEndTime).utcOffset("-00:00")}  */}
      {
        !meetInfo?.Data?.Users[0]?.meetingEndTime ?
        <JoinRoomButtons
          handleJoinRoom={handleJoinRoom}
          isRoomHost={isRoomHost}
          name={name ? name : nameValue}
          svg={svgimg}
        />:
        new Date < moment( meetInfo?.Data?.Users[0]?.meetingEndTime).utcOffset("-00:00")?
        <JoinRoomButtons
          handleJoinRoom={handleJoinRoom}
          isRoomHost={isRoomHost}
          name={name ? name : nameValue}
          svg={svgimg}
        />
        :
        <ErrorMessage className={'newErrorMessage'} errorMessage={"The meeting has ended, you are unable to join."} />
    }
      <ErrorMessage className={msgColour} errorMessage={errorMessage} />

    </>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setConnectOnlyWithAudio: (onlyWithAudio) =>
      dispatch(setConnectOnlyWithAudio(onlyWithAudio)),
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (roomId) => dispatch(setRoomId(roomId)),
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
    setHostIdAction: (hostId) => dispatch(setHostId(hostId)),
    setHostLinkAction: (hostLink) => dispatch(setHostLink(hostLink)),
    setGuestLinkAction: (guestLink) => dispatch(setGuestLink(guestLink)),
    setShowOverlayAction: (showOverlay) => dispatch(setShowOverlay(showOverlay))
  };
};

export default connect(
  mapStoreStateToProps,
  mapActionsToProps
)(JoinRoomContent);
