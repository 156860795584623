import React from "react";
import "./ButtonStyle.css";

const Button = ({ className, onClick, name, src, disabled, isWaiting}) => {
  return (
    <button
      className={`button ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {src && <img src={src} alt="" />}
      <div className={`overLapLoad ${isWaiting && 'true'}`}></div>
      {name}
    </button>
  );
};

export default Button;
