import React from "react";
import VideoButtons from "./VideoButtons";

const VideoSection = ({roomId , setPopupActive,popupActive}) => {
  return (
    // <div className="video_section_container">
      
    //   <VideoButtons roomId={roomId} />
    // </div>
    <div className="meetingFooter">
       <VideoButtons roomId={roomId} popupActive={popupActive} setPopupActive={setPopupActive}/>
    </div>
  );
};

export default VideoSection;
