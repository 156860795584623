import React, { useState, useEffect } from "react";
import MicButtonImgOff from "../../resources/images/micOff.svg";
import * as webRTCHandler from "../../utils/webRTCHandler";
const MicButton = ({stable, frontMic}) => {
  const [isMicMuted, setIsMicMuted] = useState(false);

  const handleMicButtonPressed = () => {
    webRTCHandler.sendMikeState(isMicMuted);
    webRTCHandler.toggleMic(isMicMuted);
    setIsMicMuted(!isMicMuted);
    
  };

  useEffect(() => {
    let btn = document.querySelector(".hostmuteIndicator");
    if (btn) {
      if (isMicMuted) {
        btn.innerHTML = `<img src="../assets/icons/micIcon.svg" alt="" class="videoUsername" />`;
      } else {
        btn.innerHTML = `<img src="../assets/icons/micUpper.svg" alt=""  />`;
      }
    }
  }, [isMicMuted]);

  useEffect(()=>{
    if(frontMic && stable){
      webRTCHandler.toggleMic(frontMic?false:true);
    }
   },[stable, frontMic]);

  return (
    // <div className="video_button_container">
    //   <img
    //     src={isMicMuted ? MicButtonImgOff : MicButtonImg}
    //     onClick={handleMicButtonPressed}
    //     className={`video_button_image${isMicMuted?"-active":""}`}
    //     alt="img"
    //   />
    // </div>
    <img
      // className="videoBtn"
      src={isMicMuted ? MicButtonImgOff : "../assets/icons/mic-small.svg"}
      alt=""
      onClick={handleMicButtonPressed}
    />
  );
};

export default MicButton;
